/* eslint-disable camelcase */
import React, { useState, useEffect } from "react"
import { useStoreState, useStoreActions } from "easy-peasy"
import { graphql } from 'gatsby'
import urljoin from 'url-join'
import { hasPath } from 'ramda'
import Layout from '@layouts/MainLayout'
import Container from '@layouts/Container'
import Box from '@lib/ui/core/Box'
import SEO from '@components/SEO'
import JsonLd from '@components/JsonLd'
import Post from '@components/Post/PostList'
import PostShowMore from '@components/Post/ShowMore'
import { parsePostSubcategory, parsePost } from '@services/prismic/helpers'
import itemListSchema from '@utils/json-ld/item-list'
import { linkResolver } from '@utils/link-resolver'
import AppLink from '@components/AppLink'
import SearchInput from "@components/Search/SearchInput"
import Typography from '@lib/ui/core/Typography'
import Pagination from '@material-ui/lab/Pagination'
import PaginationItem from '@material-ui/lab/PaginationItem'
import Link from '@components/Link'
import FilteredBanner from "@components/Post/FilteredBanner"
import Grid from '@lib/ui/core/Grid'
import DfpAdSlot from '@components/Ads/DfpAdSlot'
import FacebookPage from "@components/Widget/FacebookPage"
import SubcategoryBlock from '@components/Post/SubcategoryBlock'

const PostSubcategoryPage = ({ data, location }) => {
  const doc = data && data.postSubcategory
  if (!doc) return null
  const postSubcategory = parsePostSubcategory(doc)

  const { meta_keywords, meta_description, og_image } = postSubcategory

  const postDoc = data && data.posts && data.posts.nodes
  const { currentPage, pageCount } = data && data.posts && data.posts.pageInfo
  const totalCount = data && data.posts && data.posts.totalCount
  const posts = parsePost(postDoc)

  const lastPublicationDate = posts && posts[0] && posts[0].last_publication_date

  const postIds = posts.map(({ prismicId }) => (prismicId))

  const { siteMetadata } = data.site

  const toggleSearchDrawer = useStoreActions(
    ({ offerSearch }) => offerSearch.toggleSearchDrawer
  )

  useEffect(() => {
    toggleSearchDrawer(false)
  }, [])

  const path = linkResolver(postSubcategory)

  return (
    <Layout headerTitle={`${postSubcategory.name} (${totalCount})`} type="post">
      <SEO
        title={hasPath(['post_subcategory', 'name'])(postSubcategory) ? `${postSubcategory.name} | ${postSubcategory.post_category.name}` : postSubcategory.name}
        description={meta_description}
        keywords={meta_keywords}
        image={og_image && og_image.url}
      />
      <AppLink path={location.pathname} />
      <JsonLd>
        {itemListSchema({
          itemListElement: posts.map((post) => ({
            url: urljoin(siteMetadata.siteUrl, linkResolver(post)),
          })),
        })}
      </JsonLd>
      <Container>
        <Box mb={2} mt={4}>
          <Typography variant="h2">{postSubcategory.name}</Typography>
        </Box>
        <Box display="none" position="relative" mt="-1.5rem" mb={{ xs: 2.5, sm: 4 }}>
          <SearchInput height="10rem" />
        </Box>
        <FilteredBanner location={location} categoryType={false} />
      </Container>
      <Container>
      <Box mx="auto" my={{ xs: 1.5, sm: 3 }} data-ele-section="posts-list">
        <Grid container spacing={2}>
          {/* {posts.map((post) => <Box key={post.prismicId} mb={2.5}><Post post={post} fullWidth /></Box>)} */}
          <Grid item xs={12} sm={8} md={8}>
            <Post posts={posts} fullWidth />
            <Box textAlign="center" spacing={2} display="flex" justifyContent="center">
              <Pagination
                page={currentPage}
                count={pageCount}
                renderItem={(item) => (
                  <PaginationItem
                    component={Link}
                    to={`${path}/${item.page === 1 ? '' : `${item.page}`}`}
                    {...item}
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={0} sm={4} md={4} direction="column">
            <Box mb={4} textAlign="center" display={{ xs: 'none', md: 'block' }}>
              <DfpAdSlot adUnit="Web_JetsoBee/jetsobee_ros_lrec_300x250" sizes={[300, 250]} />
            </Box>
            <Box mb={4} textAlign="center" display={{ xs: 'none', md: 'block' }}>
              <DfpAdSlot adUnit="Web_JetsoBee/jetsobee_ros_lrec4_300x250" sizes={[300, 250]} />
            </Box>
            <Box mb={4} textAlign="center" display={{ xs: 'none', md: 'block' }}>
              <FacebookPage />
            </Box>
            <Box mb={4} textAlign="center" display={{ xs: 'none', md: 'block' }}>
              <SubcategoryBlock />
            </Box>
          </Grid>
        </Grid>
      </Box>
      </Container>
    </Layout>
  )
}

export default PostSubcategoryPage

export const query = graphql`
  query PostSubcategoryListQuery($id: ID!, $limit: Int!, $skip: Int!, $today: Date) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    postSubcategory: prismicPostSubcategory(prismicId: { eq: $id }) {
      data {
        name
        post_category {
          document {
            ... on PrismicPostCategory {
              data {
                name
              }
              type
              uid
            }
          }
        }
        meta_keywords
        meta_description
        og_image {
          url
        }
      }
      prismicId
      uid
      type
    }
    posts: allPrismicPost(
      limit: $limit
      skip: $skip
      sort: { fields: data___date, order: DESC }
      filter: {
        data: {
          post_subcategory: {
            id: {
              eq: $id
            }
          },
          date: {
            lte: $today
          }
        }
      }
    ) {
      totalCount
        nodes {
          prismicId
          last_publication_date
          uid
          type
          data {
            title
            date
            post_category {
              uid
              type     
              document {
                ... on PrismicPostCategory {
                  id
                  data {
                    name
                  }
                }
              }
            }
            post_subcategory {
              uid
              type
              document {
                ... on PrismicPostSubcategory {
                  id
                  data {
                    name
                    post_category {
                      uid
                    }
                  }
                }
              }
            }
            featured_images {
              image {
                fluid(maxWidth: 600, maxHeight: 320) {
                  ...GatsbyPrismicImageFluid
                }
                url
                alt
              }
            }
            lead {
              text
            }
            tagged {
              tag {
                uid
                type
                document {
                  ... on PrismicTag {
                    id
                    data {
                      name
                    }
                  }
                }
              }
            }
            author {
              uid
              document {
                ... on PrismicAuthor {
                  id
                  data {
                    name
                  }
                }
              }
            }
          }
        }
        pageInfo {
          currentPage
          pageCount
        }
      }
  }
`
